<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
      <div class="header-label col-lg-6 col-12">Charges Setting</div>
      <div
        class="col-lg-6 col-12 text-end d-flex justify-content-end custom-flex-cloumn-mob"
      >
        <button
          type="button"
          class="header-btn-outer btn btn-primary"
          @click="addSettingModalOpen()"
        >
          Add new Settings
        </button>
      </div>
    </div>
    <!-- Data Listing Start Here -->
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
      <DataTable
        :value="settingChargesList"
        :scrollable="true"
        columnResizeMode="fit"
        scrollHeight="flex"
        :paginator="true"
        :rows="30"
        :lazy="true"
        :totalRecords="totalRecords"
        @page="changePage($event)"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :loading="loading"
        dataKey="ae1"
        class="p-datatable-sm"
      >
        <template v-if="!loading" #empty>No records found.</template>
        <template #loading
          ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
        ></template>
        <Column
          field="Category"
          header="Category"
          headerStyle="width: 25%"
          bodyStyle="width: 25%"
        >
          <template #body="{ data }">
            <div>
              <div class="text-capitalize font-semibold-header">
                {{ data.ak3 ? data.ak3 : "N/A" }}
                <span v-if="data.ak3 != null || data.ak3 != ''">Star</span>
              </div>
              <!-- <div class="text-capitalize">{{ data.ae14 }}</div> -->
            </div>
          </template>
        </Column>
        <Column
          field="charges"
          header="Charges"
          headerStyle="width: 20%"
          bodyStyle="width: 20%"
        >
          <template #body="{ data }">
            <div class="text-capitalize">
              {{ data.ak5 ? data.ak5 : "N/A" }}
            </div>
          </template>
        </Column>
        <Column
          field="Repeate chargeless day"
          header="Repeate chargeless day"
          headerStyle="width: 30%"
          bodyStyle="width: 30%"
        >
          <template #body="{ data }">
            <div class="text-capitalize">
              {{ data.ak7 ? data.ak7 : "N/A" }}
            </div>
          </template>
        </Column>
        <Column
          field="status"
          header="Status"
          class="justify-content-center"
          headerStyle="width: 10%"
          bodyStyle="width: 10%"
        >
          <template #body="{ data }">
            <div>
              <InputSwitch
                v-model="data.ak6"
                :true-value="dynamicTrueActiveValue"
                :false-value="dynamicFalseInactiveValue"
              />
            </div>
          </template>
        </Column>
        <Column
          field="action"
          header="Action"
          class="justify-content-center"
          headerStyle="width: 15%"
          bodyStyle="width: 15%"
        >
          <template #body="{ data }">
            <button
              type="button"
              class="btn custom-view-detail-btn"
              @click="editSettingCharges(data)"
            >
              Edit Details
            </button>
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- Data Listing End Here -->
    <!-- Add Charges Setting modal Start Here -->
    <div class="modal-mask" v-if="isSettingCharges || isEditchargesSetting">
      <div
        class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer"
      >
        <div class="modal-content">
          <div class="modal-header custom-custmer-header-border">
            <h5 class="modal-title" id="staticBackdropLabel">
              {{
                !isEditchargesSetting
                ? 'Add New Charges Settings':'Edit New Charges Settings'
              }}
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="closeSettings()"
            ></button>
          </div>
          <div class="modal-body modal-body-scroll-outer">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Select Category<span class="text-danger">*</span></label
                  >
                  <Multiselect
                    v-model="add.category"
                    :disabled="isEditchargesSetting"
                    :options="categoryList"
                    :searchable="true"
                    label="label"
                    placeholder="Select Category"
                    class="multiselect-custom"
                    :canClear="true"
                    :closeOnSelect="true"
                    :object="true"
                    noOptionsText="No Result"
                  />
                  <div class="custom-error" v-if="v$.add.category.$error">
                    {{ v$.add.category.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Enter Charges<span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    v-model="add.charges"
                    :disabled="btnloader"
                    class="form-control text-capitalize"
                    placeholder="Enter Charges"
                    @keypress="onlyNumber"
                    autocomplete="off"
                  />
                  <div class="custom-error" v-if="v$.add.charges.$error">
                    {{ v$.add.charges.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                  <label class="form-label"
                    >Enter Repeate Chargeless Check In Days<span
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    v-model="add.repeateCheckIn"
                    :disabled="btnloader"
                    class="form-control"
                    placeholder="Enter Repeate Chargeless Check In Days"
                    autocomplete="off"
                    @keypress="onlyNumber"
                  />
                  <div class="custom-error" v-if="v$.add.repeateCheckIn.$error">
                    {{ v$.add.repeateCheckIn.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div
                  class="custom-form-group"
                >
                <label class="form-label">Status<span class="text-danger">*</span></label>
                        <div class="custom-group-radio-box-btn">
                          <input type="radio" class="btn-check" v-model="add.status" name="company-companystatus"
                            id="active" autocomplete="off" value="1" />
                          <label class="btn custom-radio-box-btn text-capitalize" for="active">Active</label>
                          <input type="radio" class="btn-check" v-model="add.status" name="gender-option"
                            id="inactive" autocomplete="off" value="0" />
                          <label class="btn custom-radio-box-btn text-capitalize" for="inactive">Inactive</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-lg-12 col-md-12 col-12 text-end">
              <button
                type="button"
                class="btn modal-bulk-next-btn"
                @click="addorUpdateChargesSettings()"
                :disabled="btnloader"
              >
                <span v-if="!btnloader">Submit</span>
                <div
                  class="spinner-border text-light custom-spinner-loader-btn"
                  role="status"
                  v-if="btnloader"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- Add Charges Setting modal End Here -->
  </template>
  
  <script>
  import { required, helpers } from "@vuelidate/validators";
  import ApiService from "../../../service/ApiService";
  import useValidate from "@vuelidate/core";
  
  export default {
    data() {
      return {
        v$: useValidate(),
        hostList: [],
        categoryList: [],
        settingChargesList: [],
        dynamicTrueActiveValue: 1,
        dynamicFalseInactiveValue: 0,
        loading: false,
        add: {
          status:1
        },
        isSettingCharges: false,
        isEditchargesSetting: false,
        clientId: "",
        rowId: "",
        totalRecords:0
      };
    },
    ApiService: null,
    created() {
      this.ApiService = new ApiService();
    },
    mounted() {
      this.loading = true;
      this.getCategories();
      this.getChargesSettings();
      },
    validations() {
      return {
        add: {
          category: {
            required: helpers.withMessage("Please select category", required),
          },
          repeateCheckIn: {
            required: helpers.withMessage(
              "Please Enter repeate chargeless check in",
              required
            ),
          },
          charges: {
            required: helpers.withMessage("Please Enter charges", required),
          },
        },
      };
    },
    watch: {
      settingChargesList(){
      const filtetedData =  this.settingChargesList.map((d) => d.ak3);
      this.categoryList = this.categoryList.filter(d=> !filtetedData.includes(d.value))
    }
  },
    methods: {
      getCategories() {
        this.ApiService.getCategories().then((data) => {
          if (data.status == 200) {
            this.categoryList = data.data;
            this.loading = false;
          }
        });
      },
      getChargesSettings() {
        this.ApiService.getChargesSettings().then((data) => {
          if (data.status == 200) {
            this.settingChargesList = data.data;
            this.totalRecords = data.count;
            this.loading = false;
          }
        });
      },
      addSettingModalOpen() {
        this.isSettingCharges = true;
      },
      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          $event.preventDefault();
        }
      },
      addorUpdateChargesSettings() {
        this.v$.add.$validate();
        var formData = new FormData();
        if (this.rowId) {
          formData.append("ak1", this.rowId);
        }
        formData.append("ak3", this.add.category.value);
        formData.append("ak4", this.add.category.label);
        formData.append("ak5", this.add.charges);
        formData.append("ak6", this.add.status);
        formData.append("ak7", this.add.repeateCheckIn);
        if (!this.v$.add.$error) {
          // this.adduserregloader = true;
          this.ApiService.addorUpdateChargesSettings(formData).then((data) => {
            if (data.status == 200) {
              // this.adduserregloader = false;
              this.settingsCharges = data.data;
              var successMsg = data.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.add.charges = "";
              this.add.category = "";
              this.add.status = "";
              this.add.repeateCheckIn = "";
            } else {
              var errorMsg = data.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
            this.isSettingCharges = false;
            this.getChargesSettings();
          });
        } else {
          this.$error;
        }
      },
      editSettingCharges(settings) {
        this.isEditchargesSetting = true;
        this.rowId = settings.ak1;
        this.add.category = {value:settings.ak3, label:settings.ak4}
        this.add.charges = settings.ak5;
        this.add.repeateCheckIn = settings.ak7;
        this.add.status = settings.ak6;
      },
      closeSettings(){
          this.isSettingCharges = false;
          this.isEditchargesSetting = false;
          this.add = {};
      }
    },
  };
  </script>
  
  <style></style>